.navbar {
  width: 100%;
  font-family: var(--typography-heading);
  text-transform: uppercase;
  background-color: var(--color-secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
}
.navLinks {
  list-style: none;
  display: flex;
  color: var(--color-font-light);
  transition: 0.3s all;
}
.link a {
  text-decoration: none;
  color: var(--color-font-light);
  padding: 0 0.5em 1em 0.5em;
  margin-right: 2em;
}
.link a.active {
  border-bottom: 1px solid var(--color-font-light);
}
.link a:hover {
  color: var(--color-primary);
}
.icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .navbar {
    position: relative;
  }

  .navLinks {
    flex-direction: column;
    position: absolute;
    text-align: center;
    width: 100%;
    top: 80px;
    left: -100%;
    transition: 0.5s all;
  }
  .navLinks.active {
    left: 0;
    background-color: var(--color-secondary);
    z-index: 9;
  }

  .link {
    padding: 10px 0;
    list-style: none;
  }
  .link a.active {
    border: none;
    color: var(--color-primary);
  }

  .icon {
    display: flex;
    color: var(--color-font-light);
  }
}
