.footer {
  flex-shrink: 0;
  width: 100%;
  font-family: var(--typography-heading);
  background-color: var(--color-secondary);
  color: var(--color-font-light);
  padding: 0.5rem;
  text-align: center;
  margin-top: 3rem;
}
.footerItem {
  padding: 0.5rem;
}
.links a {
  color: var(--color-font-light);
  padding: 1em 0.5em;
}
