.project {
  background-color: var(--color-font-light);
  border: none;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  margin: 0 0.5rem 2rem 0.5rem;
  padding: 0 0.5rem;
  max-width: 600px;
}
.img {
  width: 80%;
  display: block;
  margin: 0 auto;
  border-radius: 0 0 4px 4px;
}
.text {
  max-width: 80%;
  margin: 1rem auto;
}
.tools {
  color: var(--color-secondary);
}
.action {
  display: flex;
  padding: 0.5rem;
}

@media only screen and (max-width: 576px) {
  .action {
    flex-direction: column;
  }
}
