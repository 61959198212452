.form {
  border: none;
  border-radius: 4px;
  background-color: var(--color-font-light);
  padding: 1rem;
  margin-bottom: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
.input {
  margin-bottom: 1rem;
}
.input label {
  display: block;
  margin-bottom: 0.5rem;
}
.input input,
.input textarea {
  display: block;
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--color-secondary);
  border-radius: 4px;
}
.btn {
  font-family: var(--typography-body);
  background-color: var(--color-primary);
  cursor: pointer;
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  padding: 0.6rem;
  display: block;
  width: 100%;
  margin: 2rem auto;
  text-transform: uppercase;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: #111;
}
