.btn {
  font-family: var(--typography-body);
  background-color: var(--color-primary);
  cursor: pointer;
  border: 1px solid var(--color-primary);
  border-radius: 4px;
  padding: 0.5rem 3rem;
  text-align: center;
  display: block;
  width: 40%;
  margin: 0.5em auto;
  text-transform: uppercase;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: #111;
}
.btn:hover {
  background-color: var(--color-font-light);
  color: var(--color-primary);
}

.btnSecondary {
  background-color: var(--color-font-light);
  color: var(--color-primary);
}

@media only screen and (max-width: 576px) {
  .btn {
    width: 100%;
  }
}
